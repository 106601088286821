import { useEffect, useState } from 'react'
import omit from 'lodash/omit'
import { TaskDefinitionInterface } from '@src/interfaces/employeeOnboardingTemplate'

type FieldsKeys = keyof TaskDefinitionInterface
type ErrorsByFieldsKeys = Partial<Record<FieldsKeys, string>>
type UseValidationReturnType = {
  data: ErrorsByFieldsKeys
  isValid: boolean
  parseErrors: () => void
  resetFields: (errorFields: FieldsKeys | FieldsKeys[]) => void
}

export const useValidation = (
  values: Partial<TaskDefinitionInterface>,
): UseValidationReturnType => {
  const [errors, setErrors] = useState<ErrorsByFieldsKeys>({})
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(
      !!values.name &&
        !!values.default_assignee_type &&
        (values.default_assignee_type?.id === 'employee'
          ? !!values.default_assignee
          : values.default_assignee_type?.id === 'group'
          ? !!values.default_assignee_group
          : !!values.default_assignee_relationship),
    )
  }, [
    values.name,
    values.default_assignee,
    values.default_assignee_group,
    values.default_assignee_relationship,
    values.default_assignee_type,
  ])

  return {
    data: errors,
    isValid,
    parseErrors: () => {
      if (!values.name) {
        setErrors(prev => ({ ...prev, name: 'Task title is required' }))
      }
      if (!values.default_assignee_type) {
        setErrors(prev => ({
          ...prev,
          default_assignee_type: 'Assignee type is required',
        }))
      }
      if (values.default_assignee_type?.id === 'employee' && !values.default_assignee) {
        setErrors(prev => ({ ...prev, default_assignee: 'Assignee is required' }))
      }
      if (
        values.default_assignee_type?.id === 'group' &&
        !values.default_assignee_group
      ) {
        setErrors(prev => ({ ...prev, default_assignee_group: 'Group is required' }))
      }
      if (
        values.default_assignee_type?.id === 'relationship' &&
        !values.default_assignee_relationship
      ) {
        setErrors(prev => ({
          ...prev,
          default_assignee_relationship: 'Relationship is required',
        }))
      }
    },
    resetFields: errorFields => {
      const newErrors = omit(errors, errorFields)
      setErrors(newErrors)
    },
  }
}
